export const USER_DEFAULTS = {
  id: 'kasdioiaqw2-9uoqwej',
  authenticated: false,
  is_admin: false,
  can_approve: false,
  is_approver: false,
  is_preparer: false,
  is_superuser: false,
  is_file_uploader: false,
  show_landing_tour: false,
  show_guided_tour: false,
  show_aside_tour: false,
  show_roles_tour: false,
  show_filters_tour: false,
  show_content_tour: false,
  groups: [],
  profile: {
    id: 'askjhdalkshdlakshdlkasdioiaqw2-9uoqwej',
    email: 'test@example.com',
    identifier: '',
    client: {
      identifier: '',
      name: '',
    },
    groups: ['', ''],
    first_name: '',
    initials: '',
    is_active: false,
    is_onboarded: false,
    is_staff: false,
    is_superuser: false,
    is_tester: false,
    has_access: false,
    job_role_list: [],
    last_name: '',
    marked_for_deletion: false,
    marked_for_deletion_count: 0,
    user_permissions: [''],
    username: '',
    name: '',
  },
};

export const USER_MOCK = {
  id: '0611f9e7-b6a4-4cb8-9891-user',
  authenticated: true,
  profile: {
    id: '0611f9e7-b6a4-4cb8-9891-profile',
    username: 'kNimoh001',
    first_name: 'Gideon',
    last_name: 'Nimoh',
    name: 'Gideon Nimoh',
    initials: 'KS',
    email: 'gideon.nimoh@pwc.com',
    los: 'Advisory',
    role: 'Manager',
  },
};


const changeLog = {
  "results": [
      {
        "identifier": 'bf84-18c93bfcfe6d-b289-4b0a-e9ca2b2f',
        "action": "updated",
        "user": {
          "identifier": "e9ca2b2f-bf84-4b0a-b289-18c93bfcfe6d",
          "username": "sofia.de.morais@pwc.com",
          "first_name": "Sofia de",
          "last_name": "Morais",
          "email": "sofia.de.morais@pwc.com",
          "is_staff": true,
          "is_onboarded": true,
          "initials": "",
          "user_permissions": []
        },
        "timestamp": "2024-08-20T07:33:41.617075Z",
        "changes": [
            {
              "changed_field": "preparation_state",
              "new_value": "IN_REVIEW",
              "old_value": "CREATED"
            }
        ]
      },
  ],
  "errors": []
}
